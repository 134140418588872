import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid2 as Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Controller } from "react-hook-form";

const KitRequest = ({ control, teamCount = 0, maxKitsPerUser = 0 }) => {
  const kitsRemaining = useMemo(
    () => maxKitsPerUser - teamCount,
    [teamCount, maxKitsPerUser]
  );
  const [labelAddition, setLabelAddition] = useState("");
  useEffect(() => {
    if (maxKitsPerUser !== 0 && teamCount !== 0) {
      setLabelAddition(
        `You currently have ${kitsRemaining} kit${
          kitsRemaining > 1 ? "s" : ""
        } available to request.`
      );
    } else {
      setLabelAddition("");
    }
  }, [maxKitsPerUser, teamCount, kitsRemaining]);

  return (
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        mb={2}
        color={"secondary"}
      >
        Kit Request
      </Typography>
      <Grid container spacing={2}>
        <Grid size={12}>
          {maxKitsPerUser === 0 || teamCount < maxKitsPerUser ? (
            <Controller
              name="kit_requested"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={`Check this box to request a project board for your project.`}
                />
              )}
            />
          ) : (
            <Typography variant="body1" color="error">
              You have already requested the maximum number of kits for this
              event. Please contact eventcoordinator@most.org if you are
              interested in purchasing your own kit for additional teams or be
              notified later if additional kits become available.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

KitRequest.propTypes = {};

export default KitRequest;
