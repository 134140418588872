import React from "react";
import { useData } from "contexts/DataContext";
import { Container, Typography, Box } from "@mui/material";

const EventDayAward = () => {
  const eventID = "recuff736x9Sj2EQP";
  const { events, teams, loading, error } = useData();

  const event = events.find((e) => e.id === eventID);
  console.log("Event:", event);

  if (loading) {
    return <Typography>Loading event and team information...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  if (!event) {
    return <Typography>Event not found</Typography>;
  }

  // Filter teams based on criteria
  const eventTeams = event.data.teams
    .map((teamID) => teams.find((team) => team.id === teamID))
    .filter(
      (team) =>
        team !== undefined &&
        (team.data.withdrawn === undefined || team.data.withdrawn !== true)
    );

  console.log("Event Teams:", eventTeams);

  // Separate teams into junior and senior divisions
  const juniorTeams = eventTeams.filter(
    (team) => team.data.fx_team_division === "Junior Division"
  );
  const seniorTeams = eventTeams.filter(
    (team) => team.data.fx_team_division === "Senior Division"
  );

  // Sort teams by robotics_score in descending order
  const sortByScore = (teams) =>
    teams.sort((a, b) => b.data.robotics_score - a.data.robotics_score);

  const sortedJuniorTeams = sortByScore(juniorTeams);
  const sortedSeniorTeams = sortByScore(seniorTeams);

  // Get top four teams for each division
  const topJuniorTeams = sortedJuniorTeams.slice(0, 4);
  const topSeniorTeams = sortedSeniorTeams.slice(0, 4);

  // Function to get ordinal suffix
  const getOrdinalSuffix = (index) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = index + 1;
    const suffix =
      suffixes[((value % 100) - 20) % 10] ||
      suffixes[value % 100] ||
      suffixes[0];
    return `${value}${suffix}`;
  };

  // Special awards
  const bestTeamSpiritAwardID = "recPtJRUyvKkQa996";
  const fanFavoriteProjectAwardID = "rec2RbwP0YE6OeBJg";

  const bestTeamSpiritTeams = eventTeams.filter(
    (team) =>
      team.data.special_awards &&
      team.data.special_awards.includes(bestTeamSpiritAwardID)
  );

  const fanFavoriteProjectTeams = eventTeams.filter(
    (team) =>
      team.data.special_awards &&
      team.data.special_awards.includes(fanFavoriteProjectAwardID)
  );

  return (
    <Container>
      <Typography variant="h4" gutterBottom mt={4} align="center">
        {event.data["Event Name"]} Awards
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" gutterBottom align="center" fontWeight={600}>
          Junior Division
        </Typography>
        {topJuniorTeams.map((team, index) => (
          <Box key={team.id} mb={1}>
            <Typography fontWeight={600} align="center">
              {getOrdinalSuffix(index)} Place:
            </Typography>
            <Typography variant="h6" align="center">
              {team.data["Team PK"]}
            </Typography>
            <Typography align="center">
              {team.data["rollup_team_members_names"]}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box>
        <Typography variant="h5" gutterBottom align="center" fontWeight={600}>
          Senior Division
        </Typography>
        {topSeniorTeams.map((team, index) => (
          <Box key={team.id} mb={1}>
            <Typography fontWeight={600} align="center">
              {getOrdinalSuffix(index)} Place:
            </Typography>
            <Typography variant="h6" align="center">
              {team.data["Team PK"]}
            </Typography>
            <Typography align="center">
              {team.data["rollup_team_members_names"]}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom align="center" fontWeight={600}>
          Best Team Spirit Award
        </Typography>
        {bestTeamSpiritTeams.map((team) => (
          <div key={team.id}>
            <Typography variant="h6" align="center">
              {team.data["Team PK"]}
            </Typography>
            <Typography align="center">
              {team.data["rollup_team_members_names"]}
            </Typography>
          </div>
        ))}
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom align="center" fontWeight={600}>
          “Fan Favorite” Project Award
        </Typography>
        {fanFavoriteProjectTeams.map((team) => (
          <Typography key={team.id} align="center">
            {team.data["Team PK"]} - {team.data["rollup_team_members_names"]}
          </Typography>
        ))}
      </Box>
    </Container>
  );
};

export default EventDayAward;
