import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { debounce } from "lodash";
import {
  // countTeamsForUserAndEvent,
  // getUserByEmail,
  getCNYSEFUserByEmail,
} from "config/airtable";
import {
  validateAndCapitalizeFirstName,
  validateAndCapitalizeLastName,
  validatePhoneNumber,
} from "utils/validations.utils";
import { formatPhoneNumber } from "utils/helper.utils";

const ContactInformation = ({
  userInfoFields,
  setUserInfoFields,
  updateUserInfoField,
  // setTeamCount,
  // eventID,
}) => {
  // Local component states
  const [loading, setLoading] = useState(false);
  // const [firebaseUserExists, setFirebaseUserExists] = useState(false);
  // const [showFields, setShowFields] = useState(false);
  const [showFields, setShowFields] = useState(false);
  // const [showPasswordField, setShowPasswordField] = useState(false);

  useEffect(() => {
    if (
      userInfoFields?.email_primary?.data ||
      userInfoFields?.first_name?.data ||
      userInfoFields?.last_name?.data ||
      userInfoFields?.phone_number?.data
    ) {
      setShowFields(true);
    }
  }, [userInfoFields]);

  const debouncedCheckEmail = debounce(async (email) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setUserInfoFields((prev) => ({
        ...prev,
        email_primary: {
          ...prev.email_primary,
          error: "Please enter a valid email address.",
        },
      }));
      return;
    }

    // Clear previous error
    setUserInfoFields((prev) => ({
      ...prev,
      email_primary: { ...prev.email_primary, error: "" },
    }));

    // Save email to localStorage
    localStorage.setItem("email", email);

    try {
      // const airtableUser = await getUserByEmail(email);
      const airtableUser = await getCNYSEFUserByEmail(email);
      if (airtableUser) {
        setUserInfoFields((prev) => ({
          ...prev,
          id: airtableUser.id,
          email_primary: { ...prev.email_primary, error: "" },
          first_name: {
            ...prev.first_name,
            data: airtableUser.data.first_name,
          },
          last_name: { ...prev.last_name, data: airtableUser.data.last_name },
          phone_number: {
            ...prev.phone_number,
            data: airtableUser.data.phone_number,
          },
        }));
        // const count = await countTeamsForUserAndEvent(airtableUser.id, eventID);
        // setTeamCount(count);
        setShowFields(true);
      } else {
        setUserInfoFields((prev) => ({
          ...prev,
          id: null,
          first_name: { ...prev.first_name, data: "" },
          last_name: { ...prev.last_name, data: "" },
          phone_number: { ...prev.phone_number, data: "" },
        }));
        // setTeamCount(0);
        setShowFields(true);
      }
    } catch (error) {
      console.error("Error fetching user by email:", error);
    } finally {
      setLoading(false);
    }
  }, 1000);

  const handleEmailChange = (e) => {
    // Clear previous error
    setLoading(true);
    const value = e.target.value;
    setUserInfoFields((prev) => ({
      ...prev,
      email_primary: { ...prev.email_primary, data: value, error: "" },
    }));

    debouncedCheckEmail(value);
  };

  return (
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        mb={2}
        color={"secondary"}
      >
        Contact Information
      </Typography>

      <Grid container spacing={2}>
        {/* Email Field */}
        <Grid size={12}>
          <TextField
            label="Email Address"
            type="email"
            fullWidth
            error={!!userInfoFields?.email_primary?.error}
            helperText={userInfoFields?.email_primary?.error}
            value={userInfoFields?.email_primary?.data || ""}
            onChange={handleEmailChange}
            InputProps={{
              endAdornment: loading && userInfoFields?.email_primary?.data && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Conditional Password Field */}
        {/* {showPasswordField && (
          <Grid size={12}>
            <TextField
              label={firebaseUserExists ? "Password" : "Create Password"}
              type="password"
              fullWidth
              {...register("password", {
                required: "Password is required",
              })}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          </Grid>
        )} */}

        {/* First Name, Last Name, Phone Number Fields */}
        {showFields && (
          <>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextField
                label="First Name"
                fullWidth
                required
                value={userInfoFields?.first_name?.data || ""}
                onChange={(e) =>
                  updateUserInfoField("first_name", "data", e.target.value)
                }
                onBlur={(e) => {
                  const { error, data } = validateAndCapitalizeFirstName(
                    e.target.value
                  );
                  setUserInfoFields((prev) => ({
                    ...prev,
                    first_name: { ...prev.first_name, error, data },
                  }));
                }}
                error={!!userInfoFields?.first_name?.error}
                helperText={userInfoFields?.first_name?.error}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextField
                label="Last Name"
                fullWidth
                required
                value={userInfoFields?.last_name?.data || ""}
                onChange={(e) =>
                  updateUserInfoField("last_name", "data", e.target.value)
                }
                onBlur={(e) => {
                  const { error, data } = validateAndCapitalizeLastName(
                    e.target.value
                  );
                  setUserInfoFields((prev) => ({
                    ...prev,
                    last_name: { ...prev.last_name, error, data },
                  }));
                }}
                error={!!userInfoFields?.last_name?.error}
                helperText={userInfoFields?.last_name?.error}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                label="Phone Number"
                fullWidth
                required
                value={userInfoFields?.phone_number?.data || ""}
                onChange={(e) => {
                  const formattedValue = formatPhoneNumber(e.target.value);
                  setUserInfoFields((prev) => ({
                    ...prev,
                    phone_number: {
                      ...prev.phone_number,
                      data: formattedValue,
                      error: "",
                    },
                  }));
                }}
                onBlur={(e) => {
                  const error = validatePhoneNumber(e.target.value);
                  setUserInfoFields((prev) => ({
                    ...prev,
                    phone_number: { ...prev.phone_number, error },
                  }));
                }}
                error={!!userInfoFields?.phone_number?.error}
                helperText={userInfoFields?.phone_number?.error}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ContactInformation;
