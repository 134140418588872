const isefCategories = [
  { value: "Animal Sciences (ANIM)", label: "Animal Sciences (ANIM)" },
  {
    value: "Behavioral and Social Sciences (BEHA)",
    label: "Behavioral and Social Sciences (BEHA)",
  },
  { value: "Biochemistry (BCHM)", label: "Biochemistry (BCHM)" },
  {
    value: "Biomedical and Health Sciences (BMED)",
    label: "Biomedical and Health Sciences (BMED)",
  },
  {
    value: "Biomedical Engineering (ENBM)",
    label: "Biomedical Engineering (ENBM)",
  },
  {
    value: "Cellular and Molecular Biology (CELL)",
    label: "Cellular and Molecular Biology (CELL)",
  },
  { value: "Chemistry (CHEM)", label: "Chemistry (CHEM)" },
  {
    value: "Computational Biology and Bioinformatics (CBIO)",
    label: "Computational Biology and Bioinformatics (CBIO)",
  },
  {
    value: "Earth and Environmental Sciences (EAEV)",
    label: "Earth and Environmental Sciences (EAEV)",
  },
  { value: "Embedded Systems (EBED)", label: "Embedded Systems (EBED)" },
  {
    value: "Energy: Sustainable Materials and Design (EGSD)",
    label: "Energy: Sustainable Materials and Design (EGSD)",
  },
  {
    value: "Engineering Technology: Statics and Dynamics (ETSD)",
    label: "Engineering Technology: Statics and Dynamics (ETSD)",
  },
  {
    value: "Environmental Engineering (ENEV)",
    label: "Environmental Engineering (ENEV)",
  },
  { value: "Materials Science (MATS)", label: "Materials Science (MATS)" },
  { value: "Mathematics (MATH)", label: "Mathematics (MATH)" },
  { value: "Microbiology (MCRO)", label: "Microbiology (MCRO)" },
  {
    value: "Physics and Astronomy (PHYS)",
    label: "Physics and Astronomy (PHYS)",
  },
  { value: "Plant Sciences (PLNT)", label: "Plant Sciences (PLNT)" },
  {
    value: "Robotics and Intelligent Machines (ROBO)",
    label: "Robotics and Intelligent Machines (ROBO)",
  },
  { value: "Systems Software (SOFT)", label: "Systems Software (SOFT)" },
  {
    value: "Technology Enhances the Arts (TECA)",
    label: "Technology Enhances the Arts (TECA)",
  },
  {
    value: "Translational Medical Science (TMED)",
    label: "Translational Medical Science (TMED)",
  },
];

export default isefCategories;
