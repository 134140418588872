import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid2 as Grid,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import SelectReact from "react-select"; // for the School dropdown
import { capitalizeWords, formatPhoneNumber } from "utils/helper.utils";
import { fetchAllRecords } from "config/airtable";

const StudentInformation = ({
  control,
  errors,
  fields,
  append,
  remove,
  minStudents,
  maxStudents,
}) => {
  const [schoolOptions, setSchoolOptions] = useState([]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const schools = await fetchAllRecords("cnysef_schools", {
          view: "CNY School List",
        });
        // Format options as: { value, label }
        const options = schools.map((school) => ({
          value: school.id,
          label: school.data["school_lookup_name"],
        }));
        console.log("School options:", options);
        setSchoolOptions(options);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchSchools();
  }, []);

  return (
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        mb={2}
        color="secondary"
      >
        Team Members' Information
      </Typography>
      {fields.map((stu, index) => (
        <Box key={stu.id} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom mb={2}>
            Team Member #{index + 1}
          </Typography>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid size={{ xs: 12, sm: 3 }}>
              <Controller
                name={`students[${index}].first_name`}
                control={control}
                defaultValue={stu.first_name}
                rules={{
                  required: "First Name is required",
                  minLength: {
                    value: 2,
                    message: "First Name must be at least 2 characters",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="First Name"
                    required
                    variant="outlined"
                    fullWidth
                    error={!!errors?.students?.[index]?.first_name}
                    helperText={errors?.students?.[index]?.first_name?.message}
                    onChange={(e) =>
                      field.onChange(capitalizeWords(e.target.value))
                    }
                  />
                )}
              />
            </Grid>

            {/* Middle Name */}
            <Grid size={{ xs: 12, sm: 3 }}>
              <Controller
                name={`students[${index}].middle_name`}
                control={control}
                defaultValue={stu.middle_name}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Middle Name"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      field.onChange(capitalizeWords(e.target.value))
                    }
                  />
                )}
              />
            </Grid>

            {/* Last Name */}
            <Grid size={{ xs: 12, sm: 3 }}>
              <Controller
                name={`students[${index}].last_name`}
                control={control}
                defaultValue={stu.last_name}
                rules={{ required: "Last Name is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    variant="outlined"
                    required
                    fullWidth
                    error={!!errors?.students?.[index]?.last_name}
                    helperText={errors?.students?.[index]?.last_name?.message}
                    onChange={(e) =>
                      field.onChange(capitalizeWords(e.target.value))
                    }
                  />
                )}
              />
            </Grid>

            {/* Gender Dropdown using MUI Select */}
            <Grid size={{ xs: 12, sm: 3 }}>
              <Controller
                name={`students[${index}].gender`}
                control={control}
                defaultValue={stu.gender || ""}
                rules={{ required: "Gender is required" }}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    required
                    error={!!errors?.students?.[index]?.gender}
                  >
                    <InputLabel id={`gender-label-${index}`}>Gender</InputLabel>
                    <Select
                      labelId={`gender-label-${index}`}
                      label="Gender"
                      {...field}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Transgender">Transgender</MenuItem>
                      <MenuItem value="Non-binary/non-conforming">
                        Non-binary/Non-conforming
                      </MenuItem>
                      <MenuItem value="Prefer not to respond">
                        Prefer not to respond
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              {errors?.students?.[index]?.gender && (
                <Typography variant="caption" color="error" sx={{ pl: 1 }}>
                  {errors.students[index].gender.message}
                </Typography>
              )}
            </Grid>

            {/* School */}
            <Grid size={{ xs: 12, sm: 8 }}>
              <Controller
                name={`students[${index}].school`}
                control={control}
                defaultValue={stu.school}
                rules={{ required: "School is required" }}
                render={({ field }) => (
                  <>
                    <SelectReact
                      {...field}
                      options={schoolOptions}
                      isClearable
                      isSearchable
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption)
                      }
                      placeholder="Select School"
                      styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: errors?.students?.[index]?.school
                            ? "#d32f2f"
                            : base.borderColor,
                          height: "56px",
                        }),
                        menu: (base) => ({
                          ...base,
                          zIndex: 100,
                        }),
                      }}
                    />
                    {errors?.students?.[index]?.school && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ pl: 1 }}
                      >
                        {errors.students[index].school.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Grid>

            {/* Grade */}
            <Grid size={{ xs: 12, sm: 4 }}>
              <Controller
                name={`students[${index}].grade`}
                control={control}
                defaultValue={stu.grade}
                rules={{
                  required: "Grade is required",
                  validate: (value) =>
                    (value >= 4 && value <= 12) ||
                    "Grade must be between 4 and 12",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Grade"
                    variant="outlined"
                    required
                    fullWidth
                    error={!!errors?.students?.[index]?.grade}
                    helperText={errors?.students?.[index]?.grade?.message}
                  />
                )}
              />
            </Grid>

            {/* Adult's Full Name */}
            <Grid size={{ xs: 12, sm: 4 }}>
              <Controller
                name={`students[${index}].emergency_full_name`}
                control={control}
                defaultValue={stu.emergency_full_name}
                rules={{ required: "Adult's Full Name is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Adult's Full Name"
                    variant="outlined"
                    fullWidth
                    required
                    error={!!errors?.students?.[index]?.emergency_full_name}
                    helperText={
                      errors?.students?.[index]?.emergency_full_name?.message
                    }
                    onChange={(e) =>
                      field.onChange(capitalizeWords(e.target.value))
                    }
                  />
                )}
              />
            </Grid>

            {/* Adult's Email */}
            <Grid size={{ xs: 12, sm: 4 }}>
              <Controller
                name={`students[${index}].emergency_email`}
                control={control}
                defaultValue={stu.emergency_email}
                rules={{ required: "Adult's Email is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Adult's Email"
                    variant="outlined"
                    fullWidth
                    required
                    error={!!errors?.students?.[index]?.emergency_email}
                    helperText={
                      errors?.students?.[index]?.emergency_email?.message
                    }
                  />
                )}
              />
            </Grid>

            {/* Adult's Phone Number */}
            <Grid size={{ xs: 12, sm: 4 }}>
              <Controller
                name={`students[${index}].emergency_phone_number`}
                control={control}
                defaultValue={stu.emergency_phone_number}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Adult's Phone Number"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      field.onChange(formatPhoneNumber(e.target.value))
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2, textAlign: "right" }}>
            {fields.length > minStudents && (
              <IconButton onClick={() => remove(index)} color="error">
                <RemoveIcon />
              </IconButton>
            )}
            {fields.length < maxStudents && (
              <IconButton onClick={() => append({})} color="primary">
                <AddIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default StudentInformation;
