import React from "react";
import {
  Box,
  Grid2 as Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const AcknowledgementsCertifications = ({ register, errors }) => {
  return (
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        mb={2}
        color="secondary"
      >
        Acknowledgements and Certifications
      </Typography>
      <Grid container spacing={2}>
        {/* Confirmation of Accuracy */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                {...register("acknowledgements.confirmation", {
                  required:
                    "You must confirm that the registration information is complete and accurate.",
                })}
              />
            }
            label="Confirmation of Accuracy"
          />
          <Typography variant="body2" sx={{ ml: 4 }}>
            By checking this box, I, the registering teacher/team
            leader/student, confirm that all information provided in this
            registration form is complete and correct to the best of my
            knowledge.
          </Typography>
          {errors.acknowledgements?.confirmation && (
            <Typography variant="caption" color="error" sx={{ ml: 4 }}>
              {errors.acknowledgements.confirmation.message}
            </Typography>
          )}
        </Grid>

        {/* Eligibility and Participation */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                {...register("acknowledgements.eligibility", {
                  required:
                    "You must confirm that your project meets the eligibility criteria.",
                })}
              />
            }
            label="Eligibility and Participation"
          />
          <Typography variant="body2" sx={{ ml: 4 }}>
            I understand that this event is solely for students attending
            schools in the Central New York region and I certify that this
            project{" "}
            <strong>
              has not been entered in any other ISEF-affiliated science fairs
              other than CNYSEF
            </strong>
            . I acknowledge that participation in other ISEF-affiliated science
            fairs disqualifies the project from advancing to ISEF through
            CNYSEF.
          </Typography>
          {errors.acknowledgements?.eligibility && (
            <Typography variant="caption" color="error" sx={{ ml: 4 }}>
              {errors.acknowledgements.eligibility.message}
            </Typography>
          )}
        </Grid>

        {/* Adherence to ISEF Guidelines */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                {...register("acknowledgements.guidelines", {
                  required:
                    "You must confirm adherence to the ISEF guidelines.",
                })}
              />
            }
            label="Adherence to ISEF Guidelines"
          />
          <Typography variant="body2" sx={{ ml: 4 }}>
            I have reviewed the{" "}
            <a href="https://www.societyforscience.org/isef/international-rules/rules-and-guidelines/">
              ISEF Rules & Guidelines
            </a>{" "}
            and the{" "}
            <a href="https://www.societyforscience.org/isef/international-rules/rules-for-all-projects/#Ethics">
              Ethics Statement
            </a>
            , and confirm that this project represents my own original research,
            and that all images, charts, graphs, and other graphics are clearly
            cited on my project board.
          </Typography>
          {errors.acknowledgements?.guidelines && (
            <Typography variant="caption" color="error" sx={{ ml: 4 }}>
              {errors.acknowledgements.guidelines.message}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AcknowledgementsCertifications;
