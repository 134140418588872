import React from "react";
import { Box, Grid2 as Grid, Typography, TextField } from "@mui/material";
import Select from "react-select";
import { Controller } from "react-hook-form";
import isefCategories from "utils/isefCategories";

const ProjectInformation = ({ register, errors, control }) => {
  return (
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        mb={2}
        color={"secondary"}
      >
        Project Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={12}>
          <TextField
            label="Project Name"
            fullWidth
            {...register("project_title", {
              validate: (value) => {
                if (!value) return true; // No validation needed if empty
                if (value.length < 5) return "Project title is too short.";
                // Limit project title to 15 words by splitting the string by space
                const words = value.split(" ");
                if (words.length > 15) return "Project title is too long.";
              },
            })}
            error={!!errors.project_title}
            helperText={
              errors.project_title?.message ||
              "A project title is required for registration and can be updated later."
            }
          />
        </Grid>
        <Grid size={12}>
          <Controller
            name="project_category"
            control={control}
            rules={{ required: "ISEF category is required" }}
            render={({ field }) => (
              <Select
                {...field}
                options={isefCategories}
                isSearchable
                placeholder="Select ISEF Category"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: errors.project_category
                      ? "#d32f2f"
                      : base.borderColor,
                    height: "56px",
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                }}
                onChange={(selectedOption) => field.onChange(selectedOption)}
              />
            )}
          />
          {errors.project_category && (
            <Typography variant="caption" color="error" pl={1}>
              {errors.project_category.message}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectInformation;
