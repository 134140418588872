import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import {
  createRecord,
  createRecords,
  fetchRecord,
  getCNYSEFUserByEmail,
  updateRecord,
} from "config/airtable";

// Import sections
import ContactInformation from "./sections/ContactInformation";
import ProjectInformation from "./sections/ProjectInformation";
import StudentInformation from "./sections/StudentInformation";
import KitRequest from "./sections/KitRequest";
import AcknowledgementsCertifications from "./sections/AcknowledgementsCertifications";

// Import react-toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CNYSEFRegistrationPage = () => {
  const { eventID: paramEventID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [eventID, setEventID] = useState(null);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // State for user info fields
  const [userInfoFields, setUserInfoFields] = useState({
    id: null,
    first_name: { data: "", error: "" },
    last_name: { data: "", error: "" },
    email_primary: { data: "", error: "" },
    phone_number: { data: "", error: "" },
  });

  // Update value or error for user info fields
  const updateUserInfoField = (field, key, value) => {
    setUserInfoFields((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], [key]: value },
    }));
  };

  // React Hook Form setup
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      team_name: "",
      kit_requested: false,
      students: [
        {
          first_name: "",
          middle_name: "",
          last_name: "",
          school: "",
          grade: "",
          emergency_full_name: "",
          emergency_email: "",
          emergency_phone_number: "",
        },
      ],
    },
    mode: "onBlur",
  });

  const {
    fields: studentFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "students",
  });

  // Set hardcoded event ID or URL-based event ID
  useEffect(() => {
    if (location.pathname === "/cnysef/register") {
      setEventID("recb4GxJYFLfLuZLK");
    } else {
      setEventID(paramEventID);
    }
  }, [location.pathname, paramEventID]);

  // Fetch event details from Airtable
  useEffect(() => {
    const loadEvent = async () => {
      if (!eventID) return;
      console.log("Fetching CNYSEF event info");
      setLoading(true);
      try {
        const eventRecord = await fetchRecord("cnysef_events", eventID);
        setEvent(eventRecord);
      } catch (error) {
        console.error("Error fetching event:", error);
        setErrorMessage(error.message);
        setEvent(null);
      } finally {
        setLoading(false);
      }
    };
    loadEvent();
  }, [eventID]);

  // Use effect to fetch user record data if email is stored in local storage
  useEffect(() => {
    const email = localStorage.getItem("email");
    setUserInfoFields((prev) => ({
      ...prev,
      email_primary: { data: email || "", error: "" },
    }));

    if (email) {
      const loadUser = async () => {
        setLoading(true);
        try {
          const userRecord = await getCNYSEFUserByEmail(email);
          if (userRecord) {
            setUserInfoFields((prev) => ({
              ...prev,
              id: userRecord.id,
              email_primary: { data: userRecord.data.email_primary, error: "" },
              first_name: { data: userRecord.data.first_name, error: "" },
              last_name: { data: userRecord.data.last_name, error: "" },
              phone_number: { data: userRecord.data.phone_number, error: "" },
            }));
          }
        } catch (error) {
          console.error("Error fetching user by email:", error);
          setUserInfoFields((prev) => ({
            ...prev,
            id: null,
            first_name: { data: "", error: "" },
            last_name: { data: "", error: "" },
            phone_number: { data: "", error: "" },
          }));
        } finally {
          setLoading(false);
        }
      };
      loadUser();
    }
  }, [eventID]);

  // Handle form submission
  const onSubmit = async (data) => {
    console.log("Form data:", data);
    try {
      // Step 1: Check to see if user exists in Airtable
      let userId = userInfoFields.id;
      if (!userId || userId === null) {
        const newUserRecordData = {
          first_name: userInfoFields.first_name.data,
          last_name: userInfoFields.last_name.data,
          email_primary: userInfoFields.email_primary.data,
          phone_number: userInfoFields.phone_number.data,
        };
        const newUserRecord = await createRecord(
          "cnysef_users",
          newUserRecordData
        );
        userId = newUserRecord.id;
        setUserInfoFields((prev) => ({
          ...prev,
          id: userId,
        }));
      } else {
        // Update existing user record with any changes
        await updateRecord("cnysef_users", userId, {
          first_name: userInfoFields.first_name.data,
          last_name: userInfoFields.last_name.data,
          email_primary: userInfoFields.email_primary.data,
          phone_number: userInfoFields.phone_number.data,
        });
      }

      // Step 2: Register each student
      const studentRecords = data.students.map((student) => ({
        fields: {
          first_name: student.first_name,
          middle_name: student.middle_name,
          last_name: student.last_name,
          gender: student.gender,
          fk_school: [student.school.value],
          grade: parseInt(student.grade, 10),
          emergency_full_name: student.emergency_full_name,
          emergency_email: student.emergency_email,
          emergency_phone_number: student.emergency_phone_number,
        },
      }));

      const createdStudents = await createRecords(
        "cnysef_students",
        studentRecords
      );
      const studentIDs = createdStudents.map((student) => student.id);

      // Step 3: Create the team
      const projectRecord = {
        project_title: data.project_title,
        project_category: data.project_category.value,
        fk_students: studentIDs,
        event_year: [eventID],
        fk_registrant_user: [userId],
        kit_requested: data.kit_requested,
      };

      await createRecord("cnysef_projects", projectRecord);
      reset({
        team_name: "",
        kit_requested: false,
        students: [
          {
            first_name: "",
            middle_name: "",
            last_name: "",
            gender: "",
            school: "",
            grade: "",
            emergency_full_name: "",
            emergency_email: "",
            emergency_phone_number: "",
          },
        ],
      });
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting registration:", error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading || !eventID) {
    return (
      <Container maxWidth="md" sx={{ mt: 5, textAlign: "center" }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading event information...
        </Typography>
        <ToastContainer position="top-right" autoClose={5000} />
      </Container>
    );
  }

  if (!event) {
    return (
      <Container maxWidth="md" sx={{ mt: 5, textAlign: "center" }}>
        <Typography variant="h6" color="error">
          Event not found. Please check the URL or try again later.
        </Typography>
        <Typography variant="body1" color="error">
          URL: {window.location.href}
        </Typography>
        <Typography variant="body1" color="error">
          Error: {errorMessage}
        </Typography>
        <ToastContainer position="top-right" autoClose={5000} />
      </Container>
    );
  }

  if (submitted) {
    return (
      <Container maxWidth="md" sx={{ mt: 5, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          {`Your team has been successfully registered for the ${event.data["event_name"]}!`}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please check your email for your team registration confirmation and
          event details. For comprehensive information about the event—including
          rules & regulations, required paperwork and forms, links to ISEF
          forms, presentation and display guidelines, and support
          resources—please refer to the{" "}
          <a
            href="https://www.most.org/wp-content/uploads/2025/01/2025-CNYSEF-Manual.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Event Manual
          </a>
          . If you have any questions, feel free to contact the event
          coordinator at{" "}
          <a href="mailto:eventcoordinator@most.org">
            eventcoordinator@most.org
          </a>
          .
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mr: 2 }}
            onClick={() => {
              setSubmitted(false);
              reset();
            }}
          >
            Register Another Project
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled
            sx={{ mr: 2 }}
            onClick={() => {
              navigate("/edit-team");
            }}
          >
            Edit Project
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Home Page
          </Button>
        </Box>
        <ToastContainer position="top-right" autoClose={5000} />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      {/* Include ToastContainer to ensure notifications display */}
      <ToastContainer position="top-right" autoClose={5000} />
      <Typography variant="h4" gutterBottom>
        {` ${event.data["event_name"] || "Event"} Registration`}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ContactInformation
          userInfoFields={userInfoFields}
          setUserInfoFields={setUserInfoFields}
          updateUserInfoField={updateUserInfoField}
          eventID={eventID}
        />
        <Divider sx={{ my: 2 }} />
        <ProjectInformation
          register={register}
          control={control}
          errors={errors}
        />
        <Divider sx={{ my: 2 }} />
        <StudentInformation
          control={control}
          errors={errors}
          fields={studentFields}
          append={append}
          remove={remove}
          minStudents={event?.data["min_students_per_team"] || 1}
          maxStudents={event?.data["max_students_per_team"] || 3}
        />
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            variant="h6"
            fontWeight="bold"
            gutterBottom
            mb={2}
            color={"secondary"}
          >
            File Attachments
          </Typography>
          <Typography variant="body1" gutterBottom>
            We apologize for the inconvenience—our file upload feature is
            currently being implemented. In the meantime, please email any
            abstracts, project files, or forms to{" "}
            <a href="mailto:eventcoordinator@most.org">
              eventcoordinator@most.org
            </a>
            , including your project ID number (which you will receive after
            submitting your registration). Alternatively, you may check back in
            a week when the project information editing page becomes available.
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <KitRequest
          control={control}
          maxKitsPerUser={event?.data["max_team_per_user"] || 0}
        />
        <Divider sx={{ my: 2 }} />
        <AcknowledgementsCertifications register={register} errors={errors} />
        <Divider sx={{ my: 2 }} />
        <Box sx={{ textAlign: "center", my: 3 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={submitting}
          >
            {submitting ? "Submitting Registration" : "Submit Registration"}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default CNYSEFRegistrationPage;
